/** Contains localization strings */
const lang = {
    "en": {
        "starting_deck": "Deck",
        "starting_cards": "Starting cards",
        "draw_stacking": "Draw Stacking",
        "continue": "Continues",
        "public_lobby": "Public",
        "spectators": "Spectators",
        "enable_chat": "Chat",
        "max_players": "Max Players",
        "xray": "Hands Down",
        "infinite_draw": "Infinite Draw",
        "always_play": "Always Play",

        "who_goes_first": "Who Goes First",
        "first_player": "First player",
        "random": "Random",
        "winner": "Winner",

        "classic": "Classic",
        "all_wild": "All Wild!",
        "classic_7_0": "7-0 rule",
        "debug": "Debug",

        "off": "Off",
        "matching": "Matching",
        "any": "Any",

        "false": "",
        "true": "Enabled",

        "choose_color": "Choose color",

        "require_call": "Callouts Required",
        "call_penalty": "Callout Penalty",
        "call_draw_penalty": "Callout Draw Cards",
        "call_timer": "Callout Time"
    }
}

export default lang;